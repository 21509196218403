/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@6.65.7/lib/codemirror.min.css
 * - /npm/codemirror@6.65.7/theme/ambiance.min.css
 * - /npm/codemirror@6.65.7/addon/hint/show-hint.min.css
 * - /npm/codemirror@6.65.7/addon/lint/lint.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
